<template>
    <v-container fluid dense>
        <v-data-table dense :loading="loading" :headers="headers" :items="items" item-key="idUnique">
            <template v-slot:top>
                <v-row>
                    <v-col class="h3--text text-center font-weight-bold" :loading="loading" read-only> 
                         Plano Terapêutico 
                    </v-col>
                </v-row>
            </template> 
        </v-data-table>
    </v-container>
</template>
<script>
import api from '../../../http';

export default{
    name:'AtendExpand',
    props: {view:{type:String},url:{type:String}},
    data:()=>({headers:[
        {text:'Procedimento', value:'procedimento'},
        {text:'Frequência', value:'frequencia'},
        {text:'Qtd. procedimentos gerados', value:'qtd'},
        ], items:[], produtividade:[], resultado:[], loading:true}),
    methods:{
        make_plano_terapeutico(data){
            this.items = data[0].procedimentos
              this.resultado = `PLANO TERAPEUTICO - MODALIDADE: `+ data[0].modalidade
        },
        make_produtividade(data){
            this.items = data
            this.resultado = `PRODUTIVIDADE`
        },
        
        async get_listas(lista, url){
            const res = await api.get(`${url}`)
            try{
              this.items = res.data.map(item => ({...item, idUnique: `${item.id}-${item.frequencia}-${item.paciente}-${item.procedimento}` }))
                // if(lista==='plano_terapeutico'){
                //     this.make_plano_terapeutico(res.data)
                // }
            
                // if(lista==='produtividade'){
                //     this.make_produtividade(res.data)
                // }
            }
            catch{e=>{console.log(e)}}
            finally{ this.loading = false}
        }
    },
    mounted(){
        this.get_listas(this.view, this.url)
    }
}
</script>
